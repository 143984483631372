
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Start extends Vue {
  @Prop({ required: true, type: String }) status?: string;

  get title() {
    if (this.status === 'success') return this.$t('orderProviders.uberEats.success.title');
    return this.$t('orderProviders.uberEats.error.title');
  }

  get description() {
    if (this.status === 'success') return this.$t('orderProviders.uberEats.success.description');
    return this.$t('orderProviders.uberEats.error.description');
  }
}
